import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { useMutation, useNotify } from "ra-core";
import React from "react";
import { Booking } from "../../types";

interface Props {
  data: Booking;
}

const GenrateIssue: React.FC<Props> = (props) => {
  const { data } = props;
  const [open, setOpen] = React.useState(false);
  const notify = useNotify();
  const [handleAccept, { loading }] = useMutation(
    {
      type: "update",
      resource: "bookings",
      payload: { id: data.id ?? "", data: { action: "generate_issue" } },
    },
    {
      onSuccess: ({ data }) => {
        notify(`Successfully Generate Booking Issue.`, "info");
      },
      onFailure: (error) =>
        notify(`Generate a Booking Issue error: ${error.message}`, "warning"),
    }
  );

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Button
        color="primary"
        onClick={handleClickOpen}
        size={"small"}
        variant="contained"
      >
        Regenerate Issue
      </Button>
      <Dialog
        open={open}
        // onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Regenerate Issue?"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            The system will Regenerate a Booking Issue based on the available
            data. Please ensure all information is correct before proceeding.
            Are you sure you want to continue?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            Back
          </Button>
          <Button
            onClick={handleAccept}
            color="primary"
            disabled={loading}
            autoFocus
          >
            {loading ? "Please wait..." : "Regenerate Issue"}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default GenrateIssue;
