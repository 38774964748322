import Button from "@material-ui/core/Button";
import React, { FC } from "react";
import { EditButton, Loading, ShowActionsProps, TopToolbar } from "react-admin";
import { apiUrl } from "../../dataProvider";
import useFinance from "../../hooks/useFinance";
import { Booking } from "../../types";
import AcceptDialog from "./AcceptDialog";
import CancelDialog from "./CancelDialog";
import FinishAllocating from "./FinishAllocatingDialog";
import FinishDeliveringDialog from "./FinishDeliveringDialog";
import GenrateIssue from "./GenerateIssue";
import SendInvoiceDialog from "./SendInvoiceDialog";
import SubmitReportDialog from "./SubmitReportDialog";
import TopUpDialog from "./TopUpDialog";
import UnFinishAllocating from "./UnFinishAllocatingDialog";
import { can, OrderActions } from "./util";

export const ShowActions: FC<ShowActionsProps> = ({
  basePath,
  data,
  resource,
}) => {
  const booking = data as Booking;
  const isFinance = useFinance();

  React.useEffect(() => console.log("isFinance :>> ", isFinance), [isFinance]);

  if (!booking) {
    return <Loading />;
  }

  return (
    <TopToolbar>
      {!isFinance && can(booking, OrderActions.REVISE) && (
        <GenrateIssue data={booking} />
      )}

      {!isFinance && can(booking, OrderActions.REVISE) && (
        <EditButton
          basePath={basePath}
          record={data}
          label="Revise"
          icon={<></>}
          color="primary"
        />
      )}

      <Button
        onClick={() => {
          window
            .open(`${apiUrl}/print/bookings/${booking.id}`, "_blank")
            ?.print();
        }}
      >
        Print
      </Button>

      {!isFinance && can(booking, OrderActions.ACCEPT) && (
        <AcceptDialog data={booking} />
      )}

      {!isFinance && can(booking, OrderActions.UNFINISH_ALLOCATING) && (
        <UnFinishAllocating data={booking} />
      )}

      {!isFinance && can(booking, OrderActions.FINISH_ALLOCATING) && (
        <FinishAllocating data={booking} />
      )}

      {!isFinance && can(booking, OrderActions.FINISH_DELIVERING) && (
        <FinishDeliveringDialog data={booking} />
      )}

      {!isFinance && can(booking, OrderActions.SUBMIT_REPORT) && (
        <SubmitReportDialog data={booking} />
      )}

      {/* Finance can only send invoice */}
      {can(booking, OrderActions.SEND_INVOICE) && (
        <SendInvoiceDialog data={booking} />
      )}

      {!isFinance && can(booking, OrderActions.TOP_UP) && (
        <TopUpDialog data={booking} />
      )}

      {!isFinance && can(booking, OrderActions.CANCEL) && (
        <CancelDialog data={booking} />
      )}
    </TopToolbar>
  );
};
